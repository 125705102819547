import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import "../../assets/scss/blogs.scss";
import Page_header from "./page-header";
import blog1Img from "../../assets/images/blog1.avif";
import blog2Img from "../../assets/images/blog2.avif";
import blog3Img from "../../assets/images/blog3.avif";
import blog4Img from "../../assets/images/blog4.webp";
import blog5Img from "../../assets/images/blog5.webp";
import blog6Img from "../../assets/images/lifting-equipment.webp";
import blog7Img from "../../assets/images/blog7.webp";
import blog8Img from "../../assets/images/garbage-chute.jpg";
import blog9Img from "../../assets/images/cctv-maintenance.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
class BlogList extends Component {
  render() {
    return (
      <>
        <Page_header headertitle="Blog" subheader="Blog" />
        <div className="ltn__blog-area mb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="ltn__blog-list-wrap">

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/cctv-maintenance-services-in-uae">
                        <img src={blog9Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/cctv-maintenance-services-in-uae">
                        Reliable CCTV Maintenance Services in UAE 
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/cctv-maintenance-services-in-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            September 16, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In today’s world, security is a top priority for businesses, residential complexes, and institutions. With the growing need to protect property and people.  
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/cctv-maintenance-services-in-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                        <img src={blog8Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/best-garbage-chute-maintenance-service-in-dubai">
                        Garbage Chute Maintenance in Dubai 
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/best-garbage-chute-maintenance-service-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            September 14, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In highly urban environments like Dubai, effective waste management is a critical aspect of maintaining cleanliness, health, and safety.  
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/best-garbage-chute-maintenance-service-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                        <img src={blog7Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                        Generator Maintenance and Spare Parts Solutions in UAE Dubai  
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 10, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      In today's fast-paced world, a reliable power supply is crucial for the seamless operation of businesses and homes.  
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/lifting-equipment-in-dubai">
                        <img src={blog6Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/lifting-equipment-in-dubai">
                        Top Quality Lifting Equipment Solutions in UAE
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/lifting-equipment-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 10, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Dar Al Ataa is recognized as a premier provider of top quality lifting equipment solutions, offering an extensive array of options specifically designed to cater to the varied requirements of industries throughout the region. 
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/lifting-equipment-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/facade-cleaning-services-in-dubai">
                        <img src={blog5Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/facade-cleaning-services-in-dubai">
                        Best Facade Cleaning Services in Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/facade-cleaning-services-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 09, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                      Maintaining a building's façade is essential to both its structural integrity and its pleasing appearance. At Dar Al Ataa, we provide the best facade cleaning services that enhance your property's longevity and attractiveness. 
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/facade-cleaning-services-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/mep-maintenance-company-in-uae">
                        <img src={blog4Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/mep-maintenance-company-in-uae">
                          Innovative MEP Services in Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/mep-maintenance-company-in-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            August 09, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        In the vibrant city of Dubai, the need for the best MEP services continues to grow. MEP (Mechanical, Electrical, and Plumbing) systems, are essential for the infrastructure of contemporary buildings. Dar Al Ataa, the best among MEP maintenance companies in Dubai
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/mep-maintenance-company-in-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/best-solar-installation-in-dubai-uae">
                        <img src={blog3Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/best-solar-installation-in-dubai-uae">
                          The Best Solar Energy Systems Installation Services in
                          Dubai
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/best-solar-installation-in-dubai-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 19, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        Dubai's got sunshine in abundance, so why not put it to
                        good use? We know that understanding the solar panel
                        industry might be a little intimidating. But don’t
                        worry, Dar Al Ataa, the top solar panel installers and
                        suppliers in Dubai, UAE, is available to assist you at
                        every turn.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/best-solar-installation-in-dubai-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Item */}
                  <div className="ltn__blog-item ltn__blog-item-5 go-top">
                    <div className="ltn__blog-img">
                      <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                        <img src={blog2Img} alt="Image" />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <h3 className="ltn__blog-title">
                        <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                          pluming services
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 16, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        The plumbing problems can be a real headache. Leaky
                        faucets, clogged drains, malfunctioning toilets – these
                        issues can disrupt your daily routine and leave you
                        feeling frustrated. But fear not, Dar Al Ataa is here to
                        be your heroes in the world of pipes and fixtures with
                        our top plumbing & sanitary contracting services in
                        Dubai.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/plumbing-and-sanitary-contracting-in-dubai-uae">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Blog Item (Video) */}
                  <div className="ltn__blog-item ltn__blog-item-5 ltn__blog-item-video">
                    <div className="ltn__video-img">
                      <img src={blog1Img} alt="video popup bg image" />
                      <a
                        className="ltn__video-icon-2 ltn__secondary-bg ltn__video-icon-2-border---"
                        href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                        data-rel="lightcase:myCollection"
                      >
                        <i className="fa fa-play" />
                      </a>
                    </div>
                    <div className="ltn__blog-brief go-top">
                      <h3 className="ltn__blog-title">
                        <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                          Electromechanical Equipment Installation & Maintenance
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta">
                        <ul>
                          <li>
                            <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                              <FontAwesomeIcon
                                icon={faComments}
                                style={{ color: "#b03c3d" }}
                              />
                              35 Comments
                            </Link>
                          </li>
                          <li className="ltn__blog-date">
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              style={{ color: "#b03c3d" }}
                            />
                            July 16, 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        Dar Al Ataa LLC specializes in electromechanical
                        services and solutions, especially, in electromechanical
                        equipment installation and maintenance to optimize the
                        functionality and efficiency of your building. Our
                        tailored electromechanical work caters to diverse
                        clients in different sectors, ensuring your specific
                        needs are met.
                      </p>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-btn">
                          <Link to="/electromechanical-equipment-installation-and-maintenance-services-in-dubai">
                            <i className="fas fa-arrow-right" />
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BlogList;

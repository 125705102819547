import React, { Component } from "react";
import "../../assets/scss/HomePage.scss";
import $ from "jquery";

import client1 from "../../assets/images/client1.jpg";
import client2 from "../../assets/images/client2.jpg";
import client3 from "../../assets/images/client3.jpg";
import client4 from "../../assets/images/client4.jpg";
import client5 from "../../assets/images/client5.jpg";
import client6 from "../../assets/images/dia.jpg";
import client7 from "../../assets/images/alec.jpg";
import client8 from "../../assets/images/1001.jpg";
import client9 from "../../assets/images/amwaj.jpg";
import client10 from "../../assets/images/dsoa.jpg";
import client11 from "../../assets/images/eni.jpg";
import client12 from "../../assets/images/faisalh.jpg";
import client13 from "../../assets/images/serveu.png";
import client14 from "../../assets/images/eshara.jpg";
import client15 from "../../assets/images/spg.jpg";
import client16 from "../../assets/images/ct-16.jpg";
import client17 from "../../assets/images/ct-17.jpg";
import client18 from "../../assets/images/ct-18.jpg";
import client19 from "../../assets/images/ct-19.jpg";
import client20 from "../../assets/images/ct-20.jpg";
import client21 from "../../assets/images/ct-21.jpg";
import client22 from "../../assets/images/ct-22.jpg";
import client23 from "../../assets/images/ct-23.jpg";
import client24 from "../../assets/images/ct-24.jpg";
import client25 from "../../assets/images/ct-25.jpg";
import client26 from "../../assets/images/ct-26.jpg";
import client27 from "../../assets/images/ct-27.jpg";
import client28 from "../../assets/images/ct-28.jpg";
import avatar from "../../assets/images/avatar.jpg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

import electrical from "../../assets/images/electrical.jpeg";
import ict from "../../assets/images/ict.jpg";
import service2 from "../../assets/images/civil-works.jpeg";
import service3 from "../../assets/images/service3.jpg";
import service1 from "../../assets/images/service1.jpeg";
// import material from '../../assets/images/material1.jpg'
import material from "../../assets/images/bm2.jpg";

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  // Toggle between full comment and truncated comment
  toggleReadMore = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  // Display a portion of the comment if it exceeds the character limit
  getCommentSnippet = (text) => {
    const charLimit = 100;
    if (text.length <= charLimit) return text; // If the comment is within the limit, show full comment
    return this.state.isExpanded ? text : text.slice(0, charLimit) + "..."; // Show limited content or full based on state
  };

  render() {
    const { text } = this.props;

    return (
      <div>
        <p>{this.getCommentSnippet(text)}</p>
        {text.length > 100 && (
          <button onClick={this.toggleReadMore}>
            {this.state.isExpanded ? "Read less" : "Read more"}
          </button>
        )}
      </div>
    );
  }
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rand: "fsa" + new Date().getTime() + Math.floor(Math.random() * 100 + 1),
    };
  }
  handleLoad() {
    var id = this.state.rand;
    $(".rslides").responsiveSlides({
      auto: true, // Boolean: Animate automatically, true or false
      speed: 700, // Integer: Speed of the transition, in milliseconds
      timeout: 6000, // Integer: Time between slide transitions, in milliseconds
      pager: false, // Boolean: Show pager, true or false
      nav: false, // Boolean: Show navigation, true or false
      random: false, // Boolean: Randomize the order of the slides, true or false
      pause: false, // Boolean: Pause on hover, true or false
      pauseControls: true, // Boolean: Pause when hovering controls, true or false
      prevText: "Previous", // String: Text for the "previous" button
      nextText: "Next", // String: Text for the "next" button
      maxwidth: "", // Integer: Max-width of the slideshow, in pixels
      navContainer: "", // Selector: Where controls should be appended to, default is after the 'ul'
      manualControls: "", // Selector: Declare custom pager navigation
      namespace: "rslides", // String: Change the default namespace used
      before: function () {
        $("#" + id + " .custom-an").removeClass("fadeInDown visible");
      }, // Function: Before callback
      after: function () {
        $("#" + id + " li .custom-an").addClass("fadeInDown visible");
      }, // Function: After callback
    });
  }

  componentDidMount() {
    this.handleLoad();
  }
  render() {
    const comments = [
      "We had the opportunity to work with Dar Al Ataa Technical Services LLC for facility and property management, and we are thoroughly impressed with their level of service and professionalism.From the outset, the team at Dar Al Ataa demonstrated an exceptional understanding of facility and property management. Their approach is both meticulous and proactive, ensuring that all aspects of property upkeep and maintenance are handled efficiently and effectively. They excel in managing a range of services, including routine maintenance, emergency repairs, and overall property management, with a keen attention to detail.The staff is highly professional, responsive, and knowledgeable. They address any issues promptly and with a solution-oriented mindset, which has greatly contributed to the smooth operation of our facilities. Their communication is clear and timely, keeping us informed about the status of ongoing tasks and any required actions. Dar Al Ataa Technical Services LLC stands out for its commitment to quality and client satisfaction. Their use of advanced technology and streamlined processes helps in managing properties with utmost precision. It is evident that they value their clients and are dedicated to providing top-notch service. I highly recommend Dar Al Ataa Technical Services LLC for anyone in need of reliable and comprehensive facility and property management services. Their expertise, professionalism, and client-centric approach make them a standout choice in the industry.",
    ];
    return (
      <div id="home-page">
        <Helmet>
          <title>
            Facility Management | Facility & Property Management Services in
            Dubai, UAE
          </title>
          <meta
            name="description"
            content="Dar Al Ataa LLC the leading facility management company in UAE offering innovative solutions to meet all your property management needs."
          />
          <link rel="canonical" href="https://daralataa.com/" />
        </Helmet>
        <section id="home">
          <div id={this.state.rand} className="rslides">
            <li>
              <div className="slide-1">
                <div
                  className="animated container custom-an fadeInDown"
                  data-animation-delay="600"
                >
                  <div
                    className="large-heading"
                    style={{ textAlign: "center" }}
                  >
                    DAR AL ATAA
                  </div>
                  <div
                    className="small-heading"
                    style={{ textAlign: "center" }}
                  >
                    DRIVEN BY QUALITY
                  </div>
                  <div className="caption-large">
                    Facility & Property Management Services in Dubai UAE
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <HashLink
                      scroll={(el) =>
                        el.scrollIntoView(
                          $("html,body").animate(
                            { scrollTop: $("#about").offset().top - 60 },
                            600
                          )
                        )
                      }
                      to="about"
                    >
                      <a className="home-btn primary-btn">
                        WHO WE ARE »
                        <span>
                          <i className="icon-play" />
                        </span>
                      </a>
                    </HashLink>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="slide-2">
                <div className="animated container custom-an">
                  <div className="small-heading">ICT SOLUTIONS</div>
                  <div className="caption-small" style={{ marginTop: "20px" }}>
                    Over the years we have taken over a lot of companies and has
                    grown enormously. <br />
                    This gave us the opportunity to sharpen our expertise in
                    various sectors.
                  </div>
                  <div style={{ marginTop: "30px", textAlign: "left" }}>
                    <HashLink
                      scroll={(el) =>
                        el.scrollIntoView(
                          $("html,body").animate(
                            { scrollTop: $("#service").offset().top - 60 },
                            600
                          )
                        )
                      }
                      to="service"
                    >
                      <a className="home-btn primary-btn">
                        OUR SERVICES »
                        <span>
                          <i className="icon-play" />
                        </span>
                      </a>
                    </HashLink>
                    <Link to="/contact" className="home-btn transparent-btn">
                      GET A QUOTE »
                      <span>
                        <i className="icon-play" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="slide-3">
                <div className="animated container custom-an">
                  <div className="small-heading">INTERIOR FIT OUT WORKS</div>
                  <div className="caption-small" style={{ marginTop: "20px" }}>
                    offers high quality Interior Fit-out services ensuring the
                    needs of our clients by <br />
                    using in-house resources, guaranteeing high quality,
                    efficiency and delivery.
                  </div>
                  <div style={{ marginTop: "30px", textAlign: "left" }}>
                    {/* <a className='home-btn primary-btn'>OUR SERVICES »<span><i className='icon-play'/></span></a> */}
                    <HashLink
                      scroll={(el) =>
                        el.scrollIntoView(
                          $("html,body").animate(
                            { scrollTop: $("#service").offset().top - 60 },
                            600
                          )
                        )
                      }
                      to="service"
                    >
                      <a className="home-btn primary-btn">
                        OUR SERVICES »
                        <span>
                          <i className="icon-play" />
                        </span>
                      </a>
                    </HashLink>
                    <Link to="/contact" className="home-btn transparent-btn">
                      GET A QUOTE »
                      <span>
                        <i className="icon-play" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="innovative">
              <h2>
                <span>Innovative Facility Management & Technical Services</span>{" "}
                Company in Dubai, UAE{" "}
              </h2>

              <p>
                With 10 years of experience, we understand and value the power
                of client relationships. By building a foundation of trust and
                transparency with each client, we ensure that projects are
                completed on budget and on time.
              </p>
            </div>
            <h2>Our Core Services</h2>
            <p>
              At Dar Al Ataa LLC, we offer a broad spectrum of services, each
              tailored to meet the unique needs of our clients. Our services
              include:
            </p>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/building-facility-management-in-uae">
                    Building Facility Management:{" "}
                  </Link>
                </strong>
                From skyscrapers to sprawling residential communities, every
                building needs a comprehensive approach to facility management
                that ensures smooth operations.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/electrical-services-in-dubai-uae">
                    Electrical Works:{" "}
                  </Link>
                </strong>{" "}
                Our team of certified professionals provides only the best
                electrical services in Dubai, UAE, guaranteeing that all
                electrical and installations and repairs are performed with
                precision and safety, adhering to the latest industry standards.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/best-plumbing-service-in-dubai-uae">
                    Plumbing Works:{" "}
                  </Link>
                </strong>{" "}
                Experience top-quality plumbing solutions with Dar Al Ataa, your
                trusted partner for reliable and efficient plumbing services in
                Dubai.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/hvac-system-in-dubai-uae">
                    HVAC Works:{" "}
                  </Link>
                </strong>
                We offer all HVAC solutions, including supply installation,
                maintenance, and repair services, to ensure optimal comfort and
                indoor air quality for homes and businesses in Dubai.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/ict-service-company-in-dubai">
                    ICT Systems:{" "}
                  </Link>
                </strong>
                We provide cutting-edge ICT requirements and technical
                specifications that enhance operational efficiency and
                connectivity for businesses of all sizes.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/annual-maintenance-contracts-services-in-UAE">
                    Annual Maintenance Contracts(AMC):{" "}
                  </Link>
                </strong>
                Secure uninterrupted peace of mind with our Annual Maintenance
                Contracts, ensuring your systems receive expert care and
                seamless performance throughout the year.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/interior-fit-out-company-in-dubai-uae">
                    Interior Designing & Fit-out Works:{" "}
                  </Link>
                </strong>
                Our creative designers and skilled craftsmen bring your vision
                to life, creating functional and aesthetically pleasing spaces.
              </p>
            </div>
            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/top-civil-construction-company-in-dubai-uae">
                    Civil Works:{" "}
                  </Link>
                </strong>
                From civil construction and building engineering to finishing,
                we handle all aspects of construction with a commitment to
                quality and durability.
              </p>
            </div>

            <div>
              <p style={{ WebkitBoxOrient: "vertical" }}>
                <strong>
                  <Link to="/service/building-materials-trading-service-company-in-dubai">
                    Material Trading:{" "}
                  </Link>
                </strong>
                Our holistic approach to property and facility management
                services ensures your assets are well-maintained and managed
                efficiently making us the best facility management company in
                UAE.
              </p>
            </div>

            <h2>Facility and Property Management Services </h2>
            <p>
              We are a leading facility management company in Dubai, Our team of
              professionals is committed to offering solutions that cater to the
              unique needs of every client. From regular maintenance to urgent
              repairs, as well as full-scale property management, we guarantee
              that all facets of facility management are managed with the
              highest level of professionalism.
            </p>

            <h2>Why We Stand Out? </h2>

            <p>
              At Dar Al Ataa LLC, we prioritize a client-centric approach in all
              our projects. We strongly believe in building robust client
              relationships based on trust and transparency. This dedication
              guarantees that projects are not only completed on time and within
              budget but also exceed client expectations.
            </p>

            <p>
              Our commitment to quality and reliability is what distinguishing
              us from others in the industry. By utilizing top-notch materials
              and employing skilled professionals, we ensure that every project
              exceeds expectations.
            </p>
            <p>
              We provide a wide array of services that encompass all aspects of
              property and facility management. From electrical and plumbing
              services to interior fit-out and civil works, our diverse
              offerings cater to all your needs under one roof.{" "}
            </p>
            <p>
              The experienced team at Dar Al Ataa LLC brings a wealth of
              expertise to each project. Our professionals are committed to
              executing tasks flawlessly, utilizing their knowledge and skills
              to deliver outstanding results. This blend of experience and
              expertise guarantees that every project is handled with the utmost
              care.
            </p>

            <h2>Facility Management Services You Can Trust</h2>
            <p>
              Dar Al Ataa LLC, a reputable facility management company in Dubai,
              comprehends the significance of upholding your properties to the
              utmost standards. Our services offer assurance, ensuring that your
              assets are well taken care of. Whether you require regular
              maintenance, urgent repairs, or complete management solutions, we
              are the ideal choice for all your facility management
              requirements.
            </p>
            <h2>Sustainable and Innovative Solutions</h2>
            <p>
              Dar Al Ataa LLC, the leading facility management company in UAE,
              is dedicated to incorporating sustainable and innovative solutions
              into our services. As a prominent facility management company in
              Dubai and the UAE, we understand the significance of
              environmentally friendly practices and cutting-edge technologies
              in today's industry. Our strategy not only guarantees the
              durability and effectiveness of our projects but also contributes
              to a healthier and more sustainable environment.
            </p>

            <p>
              We provide facility management services that prioritize energy
              efficiency, waste reduction, and sustainable resource management.
              Our team is knowledgeable about the latest green technologies and
              practices, ensuring that your properties are managed with a
              minimal environmental impact.
            </p>

            <p>
              Our facility and property management services are crafted to be
              both innovative and sustainable. We utilize advanced technologies
              to enhance building performance, ranging from smart building
              systems to energy-efficient lighting and HVAC systems. These
              advancements not only lower operational costs but also improve the
              comfort and well-being of occupants.
            </p>

            <h2>
              Leading the Way in Sustainable Facility Management Solutions
            </h2>
            <p>
              Choosing Dar Al Ataa LLC means collaborating with a company that
              prioritizes sustainability and innovation. Our dedication to green
              building practices and sustainable solutions distinguishes us as a
              forward-thinking facility management company. We believe that the
              future of property management lies in intelligent, sustainable
              practices that benefit both our clients and the environment.
            </p>

            <p>
              By integrating sustainability into our facility and property
              management services, we assist you in reaching your environmental
              objectives while upholding the highest standards of excellence and
              ingenuity. Count on Dar Al Ataa LLC, the best facility management
              company in Dubai, to provide innovative solutions that promote
              efficiency, sustainability, and long-term success for your
              properties.
            </p>

            <h2>Contact Us</h2>
            <p>
              Discover why Dar Al Ataa LLC is the preferred choice for facility
              and property management services in Dubai and across the UAE.
              Contact us today to learn more about how we can assist you with
              our range of services. Let us help you maintain and enhance the
              value of your properties with our top facility and property
              management solutions.
            </p>
          </div>
        </section>

        {/* <section id='about'>
          <div className='container'>
            <div className='col-sm-6 hidden-xs'>
            </div>
            <div className='col-xs-12 col-sm-6 content'>
              <div className='heading theme-line'>
                <h3>Dar Al Ataa  LLC</h3>
              </div>
              <br/>
              <div className='about-description'>
                <p>
                  <strong>
                    Dar Al Ataa LLC has established itself as one of the leading
                    and most favoured companies to provide Electrical & Plumbing works,
                    ICT Systems, Interior Designing & Fit out works, Civil works, Annual Maintenance Contracts, Property / Facility Management services and Building Material trading in consistent with the highest standards of excellence and creativity.
                  </strong>
                </p>
                <p>
                  With 8 years of experience, we know and believe in the power of client relation. By establishing a foundation of trust and transparency with each client, we ensure that projects are completed on budget and on time.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id='service'>
          <div className='container'>
            <div className='heading'>
              <h2>WHAT WE DO</h2>
            </div>
            <br/>
            <div className='row layout'>
              <div className='col-md-4 col-sm-6 col-xs-12 service-c'>
                <Link to='/service/1'>
                  <div className='service-widget'>
                    <div className='service-image' style={{backgroundImage:`url(${service3})`}}/>
                    <div className='service-details'>
                      <div>
                        <h3>Building Facility Management</h3>
                        <p style={{WebkitBoxOrient :'vertical'}}>Dar Al Ataa has dedicated teams that provides various Building Management Services in consistent with the highest standards.</p>
                        <a className='btn btn-sm more-button'>Read More<span><i className='icon-right-open'/></span></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-md-4 col-sm-6 col-xs-12 service-c'>
                <Link to='/service/2'>
                  <div className='service-widget'>
                    <div className='service-image' style={{backgroundImage:`url(${service2})`}}/>
                    <div className='service-details'>
                      <div>
                        <h3>Civil Works</h3>
                        <p style={{WebkitBoxOrient :'vertical'}}>Our skilled technicians & labor force, along with our specialised subcontractors can perform their duties to the best satisfaction of our clients.</p>
                        <a className='btn btn-sm more-button'>Read More<span><i className='icon-right-open'/></span></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-md-4 col-sm-6 col-xs-12 service-c'>
                <Link to='/service/electrical-and-plumbing-services-in-dubai-uae'>
                  <div className='service-widget'>
                    <div className='service-image' style={{backgroundImage:`url(${electrical})`}}/>
                    <div className='service-details'>
                      <div>
                        <h3>Electrical & Plumbing Works</h3>
                        <p style={{WebkitBoxOrient :'vertical'}}>With our regional knowledge and sector expertise Dar Al Ataa Clients a fully tailored MEP solution that can take an initial concept all the way through to handover.</p>
                        <a className='btn btn-sm more-button'>Read More<span><i className='icon-right-open'/></span></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-md-4 col-sm-6 col-xs-12 service-c'>
                <Link to='/service/4'>
                  <div className='service-widget'>
                    <div className='service-image' style={{backgroundImage:`url(${ict})`}}/>
                    <div className='service-details'>
                      <div>
                        <h3>ICT Systems</h3>
                        <p style={{WebkitBoxOrient :'vertical'}}>We study, design, implement and maintain ICT system requirement of small to large organisations. We also provide consultancy and training on new ICT/IT/ELV technologies.</p>
                        <a className='btn btn-sm more-button'>Read More<span><i className='icon-right-open'/></span></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-md-4 col-sm-6 col-xs-12 service-c'>
                <Link to='/service/5'>
                  <div className='service-widget'>
                    <div className='service-image' style={{backgroundImage:`url(${service1})`}}/>
                    <div className='service-details'>
                      <div>
                        <h3>Interior Fit out Works</h3>
                        <p style={{WebkitBoxOrient :'vertical'}}>We offer a complete turnkey interior fit out solution and in order to bring the design and layouts into reality, we have a skilled team of workers comprising of civil and MEP technicians.</p>
                        <a className='btn btn-sm more-button'>Read More<span><i className='icon-right-open'/></span></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-md-4 col-sm-6 col-xs-12 service-c'>
                <Link to='/service/6'>
                  <div className='service-widget'>
                    <div className='service-image' style={{backgroundImage:`url(${material})`}}/>
                    <div className='service-details'>
                      <div>
                        <h3>Material Trading</h3>
                        <p style={{WebkitBoxOrient :'vertical'}}>We specializes in trading business of building materials that establishes a strong network with vendors and manufacturers.</p>
                        <a className='btn btn-sm more-button'>Read More<span><i className='icon-right-open'/></span></a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section> */}
        <section id="client-details">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="popular-clients">
                  <div className="heading theme-line">
                    <h3>OUR CLIENTS</h3>
                  </div>
                  <table className="client-table">
                    <tbody>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client1} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client2} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client3} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client4} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client5} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client6} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client7} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client8} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client9} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client10} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client11} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client12} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client13} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client14} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client15} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client16} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client17} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client18} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client19} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client20} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client21} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client22} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client23} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client24} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client25} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client26} />
                          </a>
                        </td>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client27} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#" target="_blank">
                            <img src={client28} />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="client-testimonials">
                  <div className="heading theme-line">
                    <h3>TESTIMONIALS</h3>
                  </div>
                  <div>
                    <div className="testimonial-content">
                      I have very much enjoyed with your services. Timely
                      completion of project and quality of work is appreciable.
                      Reliable team i would say. Keep up the quality and
                      services.
                    </div>
                    <div className="testimonial-header">
                      <div className="testimonial-avatar">
                        <img src={avatar} />
                      </div>
                      <div className="testimonial-name">
                        CA Mohammed Fazal FCA, <br />
                        Executive Director, FINTAX International Consultancy
                      </div>
                    </div>
                  </div>
                  <br /> <br />
                  <div>
                    <div className="testimonial-content">
                      I totally recommend your services and works. Quality work
                      for good money. Hoping to do more projects with Dar Al
                      Ataa.
                    </div>
                    <div className="testimonial-header">
                      <div className="testimonial-avatar">
                        <img src={avatar} />
                      </div>
                      <div className="testimonial-name">
                        Noufal Mohammed,
                        <br />
                        Manager, Amwaj Al Bahar Restaurant LLC
                      </div>
                    </div>
                  </div>
                  <br /> <br />
                  

                  <div>
                    <div className="testimonial-content">
                    {comments.map((comment, index) => (
            <Comment key={index} text={comment} />
          ))}
                    </div>
                    <div className="testimonial-header">
                      <div className="testimonial-avatar">
                        <img src={avatar} />
                      </div>
                      <div className="testimonial-name">Suncity Properties LLC</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomePage;

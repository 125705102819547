import React, { Component } from "react";
import "../../assets/scss/ServiceProfilePage.scss";
import img1 from "../../assets/images/service3.jpg";
import img2 from "../../assets/images/Civil Construction Company in Dubai.webp";
import img3 from "../../assets/images/service4.jpg";
import img31 from "../../assets/images/plumbing-services-in-dubai.jpg";
import img4 from "../../assets/images/ict2.jpg";
import img5 from "../../assets/images/interior.jpeg";
import img6 from "../../assets/images/material2.jpg";
import img7 from "../../assets/images/plumbing-2.jpg";
import img8 from "../../assets/images/hvac-2.jpg";
import img9 from "../../assets/images/amc-3.jpg";
import head1 from "../../assets/images/buildin-head.jpeg";
import head2 from "../../assets/images/civil-head.jpg";
import head3 from "../../assets/images/mep-head.jpg";
import head4 from "../../assets/images/service2.jpg";
import head5 from "../../assets/images/interior-head.jpg";
import head6 from "../../assets/images/material-head.jpg";
import head7 from "../../assets/images/plumbing-bg-1.jpg";
import head8 from "../../assets/images/hvac-bg.jpg";
import head9 from "../../assets/images/amc-bg.jpg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

class ServiceProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      services: [],
      data: {},
      points: [],
      description3: [],
      description4: [],
      description5: "",
      description6: [],
      description7: [],
      description11: [],
      description12: [],
      description13: [],
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getServices();
    const { match } = this.props;
    const id = match.params.id;
    const data = this.state.services.find((item) => String(item.id) === id);
    if (data) {
      await this.setState({
        id,
        data,
        points: data.points,
        description3: data.description3 || [],
        description4: data.description4 || [],
        description5: data.description5 || "",
        description6: data.description6 || [],
        description7: data.description7 || [],
        description11: data.description11 || [],
        description12: data.description12 || [],
        description13: data.description13 || [],
      });
    }
  }

  async getServices() {
    const services = [
      {
        id: "building-facility-management-in-uae",
        title: "Best Building Management Service in UAE |  Building Facility Management in Dubai",
        pagedescription:
          "Dar Al Ataa offers best building management service in UAE that cater to the diverse needs of our clients across various sectors, including commercial, residential, hospitality, and retail.",
        canonical: 'https://daralataa.com/service/1',
        headImage: head1,
        img: img1,
        name: "Building Facility Management in Dubai - UAE",
        description:
          "From skyscrapers to sprawling residential communities, every building needs a comprehensive approach to facility management that ensures smooth operations, while improving the occupants' experience. The demand for top-quality building facility management solutions is at an all-time high in the dynamic city of Dubai. As the bustling city continues to grow and its infrastructure is setting new benchmarks for urban development worldwide, the need for efficient, reliable, and pragmatic facility management services becomes increasingly important.",
          points: [
            { name: "Cleaning Services",
              link: "/facade-cleaning-services-in-dubai",
             },
          { name: "Garbage Chute Maintenance & Repairing",
            link: "/best-garbage-chute-maintenance-service-in-dubai",
           },
          { name: "MEP & General Maintenance",
            link: "/mep-maintenance-company-in-uae",
           },
          
          { name: "Lifts & Lifting equipment",
            link: "/lifting-equipment-in-dubai"
           },
           { name: "CCTV & Networking Maintenance",
             link : "/cctv-maintenance-services-in-uae"
            },
           { name: "All Types of AC Services" },
           { name: "Water Tank Cleaning" },
           { name: "Waste Management" },
           { name: "Swimming Pool Maintenance" },
           { name: "Landscaping" },
          { name: "Standby Generator/repair/spare parts",
            link: "/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai"
           },
          
          
          
          
          
          
          { name: "Pest Control" },
          
        ],
        description12:
        "At Dar Al Ataa, we understand that the essence of effective facility management lies in effective planning and execution. We offer the best quality building management service in UAE that caters to the diverse needs of our clients across various sectors, including commercial, residential, hospitality, and retail. Our team of experienced professionals is well-equipped with the latest technology and industry best practices to deliver unmatched services that meet the highest efficiency standards.",
        heading13:
        "Our Comprehensive Services",
        description13:
        "Dar Al Ataa offers the finest solutions for building facility management in Dubai. The services encompass an array of activities that include Garbage Chute Maintenance & repair, Cleaning services, MEP & General maintenance, Lifts & Lifting Equipment, CCTV & Networking maintenance. These services are aimed at maintaining and improving the functionality, comfort, and longevity of your buildings.",
        heading14:
        "Garbage Chute Maintenance & Repairing",
        description14:
        "In Dubai's high-rise buildings, garbage chutes are necessary for effective waste management. Regular maintenance and repair of these systems are needed to prevent blockages, odours, and potential health hazards. At Dar Al Ataa, we provide quality garbage chute maintenance and repair services to ensure your chutes function smoothly and hygienically. Our team uses advanced techniques to clean and service chutes, improving efficiency and prolonging their lifespan. With our solutions, you can maintain a clean and safe environment for all the occupants.",
        heading15:
        "MEP & General Maintenance",
        description15:
        "In Dubai, maintaining the mechanical, electrical, and plumbing (MEP) systems in top condition is necessary for the smooth operation of any building. At Dar Al Ataa, we offer comprehensive MEP and general maintenance services that ensure the safety, efficiency, and reliability of your property's infrastructure. Our experts will handle everything from constant inspections and preventive maintenance to emergency repairs, addressing challenges before they become costly problems. We also offer general maintenance services that cover all aspects of building maintenance, ensuring that your property remains in the best condition.",
        heading16:
        "Preventive Maintenance",
        description16:
        "Preventive maintenance is necessary to ensure the longevity and efficiency of your building’s systems and infrastructure. Our building maintenance in UAE includes maintenance strategies designed to identify and address potential issues before they turn into costly repairs. Our comprehensive preventive maintenance programs encompass all aspects of building operations including HVAC systems, electrical components, plumbing, and more. We help minimize downtime, improve operational efficiency, and extend the lifespan of your assets.",
        heading17:
        "Water Tank Cleaning",
        description17:
        "Regular water tank cleaning is necessary to prevent the buildup of harmful elements that can compromise water quality. At Dar Al Ataa, we offer professional water tank cleaning services using advanced cleaning techniques and environmentally safe products to make sure that the highest standards of hygiene and safety are met. Our experienced team cleans and disinfects your water tanks, ensuring compliance with local health regulations and protecting the well-being of your occupants.",
        heading18:
        "Lifts & Lifting Equipment",
        description18:
        "Dar Al Ataa provides comprehensive maintenance and repair services for all lifts and lifting equipment to ensure smooth operation. With our skilled technicians and building maintenance units in UAE, we manage routine inspections, preventive maintenance, and emergency repairs, reducing downtime and enhancing the user experience. By adhering to strict safety standards and using the best technologies, we ensure that your lifts and lifting equipment operate at peak performance, offering safe and efficient transportation for all occupants.",
        heading19:
        "Why Choose Us?",
        description19:
        "Dar Al Ataa is a trusted partner dedicated to excellence. Our deep understanding of the Dubai market, coupled with our premium quality building management service in UAE, sets us apart from the other competitors. We work closely with our clients to craft customized facility management strategies that align with their goals, ensuring a seamless experience.",
        heading20:
        "Connect with Us!",
        description20:
        "Connect with us and learn how our building facility management solutions can elevate your property. To learn more about our services and how we can help you achieve your facility management objectives in Dubai, you can contact us."
      },
      {
        id: "top-civil-construction-company-in-dubai-uae",
        canonical: 'https://daralataa.com/service/top-civil-construction-company-in-dubai-uae',
        headImage: head2,
        img: img2,
        title: "Top Civil  Construction in UAE | Best Civil Construction Company in Dubai",
        pagedescription:
          "At Dar Al Ataa, we are dedicated to offering top civil construction in UAE. Our solutions are designed to meet the ever growing needs of Dubai’s infrastructure landscape. ",
        name: "Top Civil Construction Company in Dubai",

        description8_1:
          "Dar Al Ataa is a leading civil construction company in Dubai. We offer comprehensive civil construction work solutions that are designed to meet the ever-growing needs of Dubai’s infrastructure landscape. With a strong foundation in quality and precision, our team of seasoned professionals handles every aspect of civil construction with utmost sincerity and strives hard to bring your architectural visions to life. Our wide range of solutions includes Demolition Works, Excavation Works, Block Work & Plastering Works, Flooring Works, Painting Works, Gypsum Works, and much more. With a deep commitment to excellence, safety, and sustainability, we make sure that every single project we undertake meets the rigorous quality standards of Dubai's construction environment. Whether you're developing new residential complexes, commercial spaces, or public infrastructure, Dar Al Ataa is your most trusted partner in achieving exceptional results.",
          
          headding7:
          "Navigating Challenges with Quality Expertise",
        
        description2_1:
          "With a team of highly skilled construction professionals, we bring unparalleled expertise to every single construction project. Whether it's indulging in excavation works, constructing state-of-the-art commercial buildings, or developing fundamental infrastructure, we ensure that your project is completed in a timely and effective manner. We understand the complexities of working in Dubai’s challenging environment, and our solutions are tailored to meet both the technical and regulatory requirements of this lively market.",
        
        headding3: "Embracing Technology",
        description3: 
          "As a prominent building construction company in UAE, we embrace the most modern technologies and construction methods to deliver efficient, cost-effective, and sustainable solutions. We leverage cutting-edge tools to boost project accuracy, reduce timelines, and minimize environmental impact. Our deep commitment to service excellence and innovation extends to every aspect of our work, ensuring that we remain at the forefront of the civil construction industry in Dubai.",
      
        headding4: "Focusing on Sustainability",
        description4: 
        "As the cities continue to grow, so does the need for sustainable development. Dar Al Ataa, the top civil engineering company in UAE, is committed to incorporating environmentally friendly practices into our construction processes. We strictly adhere to Dubai’s green building regulations, using sustainable materials and energy-efficient systems to create structures that are functional, aesthetically pleasing, and eco-friendly.",
        
        headding5: "Contributing to Dubai’s Construction Industry",
        description5:
          "Our civil construction work solutions are contributing to the city’s ongoing transformation, providing the foundation for ambitious growth. By offering tailored services, embracing innovation, and navigating challenges with unmatched expertise, we are building a prosperous future for the coming generations, one project at a time. As the city continues to grow, we will remain the key player in shaping Dubai’s civil construction landscape and offer continued support in its journey as a global leader in urban development.",
        
          headding6: "Join Us in Building a Legacy!",
          description6:
            "At Dar Al Ataa, we are more than just service providers; we are credible partners in creating the future of UAE and the world. Whether you're planning to build a new space or looking to upgrade existing infrastructure, we have the right expertise and resources to make your project a grand success. We are dedicated to offering top civil construction in UAE. Let us help you build an inspiring legacy that will stand the test of time.",
           

        points: [
          { name: "Demolish Works" },
          { name: "Excavation Works" },
          { name: "Reinforced Cement Concrete(RCC), Plain Cement Concrete(PCC)." },
          { name: "Block Work & Plastering Works" },
          { name: "Water Proofing." },
          { name: "Gypsum Works – Partitions, Cladding, Ceiling." },
          {
            name: "Flooring Works – Raised Floor, Tiles, Marble, Parquet, Carpet.",
          },
          
          
          {
            name: "Painting Works – Ceiling, Wall, Decorative Stucco, Epoxy Painting.",
          },
        ],
      },
      {
        id: "electrical-services-in-dubai-uae",
        canonical: 'https://daralataa.com/service/electrical-services-in-dubai-uae',
        headImage: head3,
        img: img3,
        img31: img31,
        title: "Electrical Services in Dubai UAE",
        pagedescription:
          "Dar Al Ataa offers top-quality electrical services in UAE, ensuring reliable and efficient solutions for all your residential and commercial needs.",
        name: "Best Electrical Solution Company in UAE",
        description2:
          "Dar Al Ataa provides an extensive array of the best electrical services in UAE to meet the requirements of both residential and commercial sectors. Our proficiency in electrical contracting, installation of electromechanical equipment, lighting solutions in Dubai, cabling, and panel installations guarantees the delivery of superior quality outcomes for every undertaking. Below is a summary of our offerings, showcasing our expertise and the application of advanced technology.",
        headding2:
          "Electrical Contracting and Installation of Electromechanical Equipments",
        
        description2_1:
          "We stand at the forefront of electrical contract in Dubai, offering a full spectrum of services suitable for projects of any scale. Our installation and maintenance of electromechanical equipment makes all systems function efficiently and dependably. We excel in integrating electrical systems, enhancing both usefulness and safety, and establishing our reputation as the best electrical solution company in UAE.",
        
        description2_2:
          "Our team manages every facet of electromechanical equipment installation, from the initial setup to continuous maintenance. Our electrical services in UAE are of the best quality and accuracy guarantee that your equipment operates at the top levels, minimizing downtime and maximizing operational efficiency.",
        
        headding3: "Lighting and Light Fixtures",
        description3: 
          "Lighting is essential for establishing the appropriate atmosphere and functionality within any environment. At Dar Al Ataa, we offer the best lighting installation in Dubai, presenting various solutions customized to your unique requirements. Our proficiency in lighting, light fittings, and light fixtures in Dubai enables us to provide innovative designs that enhance the visual appeal of your property.",
          description3_1:
          "As a prominent lighting consultant in Dubai, we deliver tailored solutions that harmonize style with practicality. Our lighting offerings in Dubai are made with the highest standards of efficiency and sustainability. We provide an extensive array of light fixture installation services in UAE, ensuring that each project is well-executed.",

        headding4: "Cable Gland and Cable Termination in Dubai",
        description4: 
        "Effective cable management is vital for the safety and performance of any electrical system. Dar Al Ataa specializes in cable gland and termination services in Dubai, offering dependable solutions for both residential and commercial applications. Our team possesses expertise in electrical cable glanding and fixing, cable gland, and Cable termination in UAE, guaranteeing safe connections that mitigate potential risks.",
        description4_1:
        "We provide thorough cable jointing and termination works. Our specialization in cable gland termination and electrical glanding positions us as a trusted provider in UAE. Our cable termination services in UAE make all electrical systems operate safely, efficiently, and reliably.",
        
        headding5: "LV & HT Panel Installation",
        description5:
          "Dar Al Ataa stands as a leader in the installation of LV and HT panels, delivering high-quality solutions for a diverse range of applications. Our expertise in HT panel services in Dubai guarantees that your high-tension panels are installed and maintained to the utmost standards. We are recognized for our exceptional HT panel installation services in UAE, providing reliable solutions for even the most intricate projects.",
          description5_1:
          "Our team of professionals in HT panel systems in UAE possesses extensive knowledge in all facets of panel installation and maintenance, guaranteeing the efficient and safe operation of your systems. As contractors specializing in low voltage panels in Dubai, we provide thorough electrical panel installation services in UAE for both residential and commercial sectors. Our industry LV HT panel installation services address the unique requirements of your project, ensuring dependable performance and optimal efficiency.",
          description5_2:
          "Dar Al Ataa stands as your premier provider of the best electrical services and electrical contract in UAE. Our proficiency in lighting, cabling, and panel installation makes us the best electrical solution company in UAE. Rely on us to offer the best electrical services and electrical contract in UAE, customized to fulfill your specific requirements.", 
       
          headding6: "Solar Panel Installers and Suppliers in Dubai, UAE",
        description6:
        "Dar Al Ataa is a leading provider of solar installation in Dubai, offering innovative solutions that harness the power of the sun to meet the energy needs of residential and commercial clients. Our team of expert solar panel installers and suppliers in Dubai, UAE, is committed to delivering top-quality services that make your solar system installed with efficiency. Our services, from initial consultation and system design to installation and maintenance, make the process of installing solar panels in Dubai seamless and hassle-free. Our solar solutions are tailored to maximize energy efficiency and reduce environmental impact, providing a sustainable and cost-effective energy source for your property.",
        description6_1:
        "As experienced solar power contractors in the UAE, Dar Al Ataa promotes renewable energy through our solar solutions. We work closely with our clients to understand their energy needs and design customized systems that optimize energy production and reduce utility costs. We ensure that our solar installation in UAE meets the highest standards of performance and reliability. By choosing Dar Al Ataa, you are investing in a cleaner, greener future with our expert solar panel installations that promise long-term energy savings and environmental benefits.",

       
        points: [
          { name: "Electrical Contracting" },
          { name: "Electromechanical Equipment Installation & Maintenance" },
          { name: "Lighting & Light Fixtures" },
          { name: "Cabling, Glanding and Termination" },
          { name: "LV & HT Panel Installation and Dressing" },
          { name: "All kind of Electrical Maintenance" },
        ],
      },

      {
        id: "best-plumbing-service-in-dubai-uae",
        canonical: 'https://daralataa.com/service/best-plumbing-service-in-dubai-uae',
        headImage: head7,
        img: img7,
        img31: img31,
        title: "Best Plumbing Services in Dubai",
        pagedescription:
          "Dar Al Ataa delivers the best plumbing services in Dubai, so that your home and business are always in perfect flow with expert solutions.",
        name: "Best Plumbing Services in Dubai - UAE : Expert Solutions",
        description8_1:
          "When it comes to reliable plumbing services in UAE, Dar Al Ataa stands out as a leading provider, offering a full range of plumbing solutions to meet the diverse needs of residential and commercial clients. As one of the most trusted plumbing contractors in UAE, we are committed to delivering top-notch services that ensure the smooth functioning of your plumbing systems. From minor repairs to comprehensive installations, our team of highly skilled professionals is equipped to handle any plumbing challenge with precision and expertise.",
          description8_2:
          "Our customer satisfaction positions us as the preferred provider of plumbing services in Dubai. We recognize the significance of a properly functioning plumbing system and its impact on the comfort and safety of your residence or commercial establishment. Therefore, we deliver personalized solutions designed to meet your unique needs, guaranteeing that each task is executed to the utmost standards.",
          headding7:
          "Plumbing and Sanitary Solutions in UAE",
        
        description2_1:
          "Dar Al Ataa is the top plumbing and sanitary contractors in UAE. Our services encompass everything from routine maintenance to complex plumbing installations. Our team has the expertise to get the job done efficiently and effectively. We use the latest tools and technology to diagnose and repair any plumbing issue, minimizing disruption and ensuring long-lasting results.",
        
        description2_2:
          "In addition to general plumbing services, we also specialize in sanitary installations and maintenance, making us the preferred choice for businesses and homeowners alike. Our comprehensive approach ensures that all aspects of your plumbing and sanitary systems are in optimal condition, providing you with peace of mind and a comfortable living environment.",
        
        headding3: "Trusted Plumbing Contractors in UAE",
        description3: 
          "As a leading plumbing contractors in UAE, Dar Al Ataa maintains the highest standards of professionalism and excellence. We are not only experienced but also continuously trained to stay up-to-date with the latest industry practices. This allows us to offer innovative solutions that not only fix the immediate issue but also prevent future problems, saving you time and money in the long run.",
          description3_1:
          "We believe in building long-term relationships with our clients by providing reliable and affordable services. Our reputation as one of the best plumbers in UAE is built on our commitment to quality, integrity, and customer satisfaction.",

        headding4: "Choose Dar Al Ataa for Plumbing Services",
        description4: 
        "Choosing Dar Al Ataa for your plumbing services in UAE means opting for quality, reliability, and peace of mind. We understand that plumbing issues can be stressful, which is why we offer prompt and efficient service to address your needs as quickly as possible. Our team is available around the clock to respond to emergencies, ensuring that you never have to wait long for assistance.",
        description4_1:
        "Our focus on customer satisfaction extends beyond just fixing your plumbing issues. We take the time to understand your specific needs and offer personalized solutions that are both effective and cost-efficient. This commitment to excellence is what makes us one of the best plumbing services in Dubai and a trusted partner for clients across the UAE.",
        
        headding5: "The Best Plumbers in UAE",
        description5:
          "When it comes to finding the best plumbers in UAE, look no further than Dar Al Ataa. Our team is made up of highly skilled and experienced professionals who are dedicated to providing top-quality service. Our work and go above and beyond to ensure that every job is completed to the highest standards. Whether it's a small repair or a major installation, you can trust us to deliver exceptional results every time.",
          description5_1:
          "Our range of services, coupled with our commitment to quality and customer satisfaction, makes Dar Al Ataa the preferred choice for all your plumbing needs. Contact us today to learn more about how we can help you maintain a safe and efficient plumbing system.",

        points: [

          { name: "Plumbing & Sanitary Contracting." },
          { name: "Water Pumps & Sewage Pumps Works & Maintenance." },
          { name: "Water Heater Supply & Installation." },
          { name: "All kind of PPR, HDPE, UPVC Piping." },
          { name: "Drainage Works - Grease Trap & Manhole Works." },
          { name: "Irrigation System Work." },

        ],
      },

      {
        id: "hvac-system-in-dubai-uae",
        canonical: 'https://daralataa.com/service/hvac-system-in-dubai-uae',
        headImage: head8,
        img: img8,
        img31: img31,
        title: "Best HVAC company in Dubai - UAE",
        pagedescription:
          "Dar Al Ataa is the best HVAC company in Dubai, offering comprehensive HVAC system solutions and expert HVAC maintenance in UAE to ensure optimal performance and comfort in every environment.",
        name: "Optimize Comfort with the Best HVAC System in Dubai",

        description8_1:
          "Ensuring a comfortable, secure, and energy-efficient indoor atmosphere necessitates a dependable HVAC system. At Dar Al Ataa, we take pride in being recognized as the best HVAC company in Dubai, providing exceptional HVAC maintenance in UAE. Our extensive array of HVAC offerings encompasses everything from installation and ductwork to testing and commissioning, establishing us as the preferred provider for all your HVAC requirements.",
           headding7:
          "Comprehensive HVAC Services in Dubai",
        
        description2_1:
          "Dar Al Ataa specializes in delivering complete HVAC system solutions in Dubai. We provide a diverse selection of HVAC services, including the installation and maintenance of various air conditioning units such as VRF, FCU, AHU, FAHU, package units, ducted systems, and decorative air conditioners. Our proficiency covers all facets of HVAC system operations, ensuring that your air conditioning system operates efficiently and dependably year-round.",
        
      
        headding3: "Professional HVAC Maintenance in `UAE",

        description13:
        "Regular maintenance of your HVAC system is vital for its durability and efficiency. As the best HVAC company in Dubai, we offer professional HVAC maintenance in UAE, addressing all aspects of system care. Our maintenance offerings include:",

        description3: (
          <>
            <strong>VRF/FCU/AHU/FAHU/Package/Ducted Air Conditioners:  </strong> 
            We provide comprehensive maintenance and repair services for all types of air conditioning units, ensuring optimal performance and energy efficiency.
          </>
        ),
        description3_1: (
          <>
            <strong>GI & PI Ducting Works: </strong> 
            Our team specializes in the installation and maintenance of galvanized iron (GI) and pre-insulated (PI) ductwork to ensure proper airflow and energy savings.
          </>
        ),
        description3_2: (
          <>
            <strong>Piping Works: </strong> 
            We handle all types of piping works, including chilled water (CHW) pipes and copper piping, to maintain the integrity of your HVAC system.
          </>
        ),
        description3_3: (
          <>
            <strong>Grills and Diffusers: </strong> 
            We supply and install a variety of grills and diffusers to enhance the airflow and ventilation in your space.
          </>
        ),
        description3_4: (
          <>
            <strong>Exhaust Ventilation Systems: </strong> 
            We design, install, and maintain exhaust ventilation systems to remove stale air, smoke, or pollutants, ensuring a healthier indoor environment.
          </>
        ),
        headding4: "Optimizing Your HVAC System in Dubai with Dar Al Ataa",
        description4: 
        "An effective HVAC system is essential for ensuring a comfortable indoor atmosphere in the hot and humid climate of Dubai. At Dar Al Ataa, we are dedicated to helping you achieve optimal system performance through our comprehensive range of HVAC services in Dubai. From the installation of energy-efficient units to regular maintenance in the UAE, we guarantee that your system operates efficiently, thereby lowering energy expenses and enhancing indoor air quality.",
     
        headding5: "Testing and Commissioning of HVAC Systems in Dubai",
        description5:
          "In line with our commitment to excellence, we conduct meticulous testing and commissioning of HVAC systems. This process ensures that every system we install adheres to the highest standards of safety, performance, and energy efficiency. By selecting Dar Al Ataa, you can be confident that your HVAC system in Dubai will be fine-tuned for maximum performance and durability.",
          
          headding6: "Your Reliable Partner for HVAC Maintenance in the UAE",
        description6:
          "Routine maintenance is crucial for prolonging the lifespan of your HVAC system and avoiding expensive repairs. As the best HVAC company in Dubai, Dar Al Ataa provides extensive maintenance services in UAE, encompassing everything from regular inspections to urgent repairs. Our maintenance offerings are designed to keep your system operating optimally, granting you peace of mind and a comfortable indoor setting.",
          
          headding7: "Contact Dar Al Ataa: The Premier HVAC Company in Dubai",
          description7:
            "Are you in search of dependable HVAC services in Dubai? Rely on Dar Al Ataa, the best `HVAC company in Dubai, to deliver high-quality solutions customized to your requirements. Whether you require HVAC maintenance in UAE or a new system installation, we are ready to assist you. Reach out to us today to arrange a consultation and discover the advantages of partnering with the best in the industry.",
            
        
        points: [

          { name: "VRF/FCU/AHU/FAHU/Package/Ducted/ Decorative Air Conditioner." },
          { name: "GI & PI Ducting Works." },
          { name: "Piping Works (CHW pipes / Copper piping)." },
          { name: "Grills and Diffusers." },
          { name: "Testing and commissioning of HVAC Systems." },
          { name: "Exhaust Ventilation system." },

        ],
      },

      {
        id: "ict-service-company-in-dubai",
        canonical: 'https://daralataa.com/service/ict-service-company-in-dubai',
        headImage: head4,
        img: img4,
        title: "ICT Service Company in Dubai | Best ICT Solution in UAE",
        pagedescription:
          "Dar Al Ataa delivers innovative ICT solutions in Dubai, integrating connectivity, security, and automation for smarter, more efficient environments.",
        name: "Empowering Your Business with the Best ICT Solutions in Dubai ",
        description8_1:
          "In today’s fast-paced digital landscape, connectivity, and robust technology infrastructure are essential for any business aiming to succeed. At Dar Al Ataa, we know the importance of reliable ICT systems, which is why we are a trusted ICT service provider in UAE. Our ICT solutions in Dubai are designed to meet every need of businesses, providing them with the tools they need to stay competitive.",
         headding7:
          "A Legacy of Innovation and Reliability",
        
        description2_1:
          "As a leading ICT service company in Dubai, Dar Al Ataa offers solutions that integrate the latest technological advancements with your business operations. We deliver innovative ICT services that not only solve current challenges but also anticipate future needs. Our experts work closely with you to understand your business goals, so that our ICT solutions in Dubai align perfectly with your objectives.",
        
        description2_2:
          "From network infrastructure to cybersecurity, our ICT service provider in UAE delivers a full spectrum of services. Upgrading your existing systems, implementing new technologies, etc.. everything is made possible with Dar Al Ataa’s ICT Solutions. Our team is here to provide expert guidance and support every step of the way. We are the most preferred ICT service company in Dubai, trusted by businesses across various industries.",
        
        headding3: "Your Partner in Digital Transformation",
        description3: 
          "Digital transformation is more than just a buzzword; it’s a necessity for businesses that want to thrive in the modern world. Our ICT solutions in Dubai are designed to drive your digital transformation efforts, making your operations more efficient, secure, and scalable. As a top ICT service provider in UAE, we help you harness the power of technology to improve productivity and enhance customer experiences.",

        headding4: "Innovative, Secure, and Scalable Solutions",
        description4: 
        "Security is at the forefront of our ICT solutions in Dubai. We understand the growing threats in the digital world and ensure that your systems are protected with the latest cybersecurity measures. As a forward-thinking ICT service company in Dubai, we also focus on scalability, providing the best ICT solutions in UAE that can grow with your business, ensuring long-term success.",

        headding5: "Tailored Support and Maintenance",
        description5:
          "Our services don’t end with the implementation of our ICT solutions in Dubai. As a leading ICT service provider in UAE, we offer ongoing support and maintenance to ensure your systems run smoothly at all times, allowing you to focus on what matters most—growing your business.",
         
          headding6: "Future-Proof Your Business with the Best ICT Solutions in UAE",
          description6:
          "Choosing Dar Al Ataa as your ICT service company in Dubai means partnering with a team that understands your business's evolving needs. Our ICT solutions in Dubai are designed to provide the flexibility, security, and innovation required to stay ahead in today’s competitive market. Together, we can build a future where your business thrives on the strength of cutting-edge technology.",
          
        points: [
          { name: "CCTV Systems" },
          { name: "Access Control & Security Systems" },
          { name: "Structural Cabling & Networking" },
          { name: "Audio & Video Systems" },
          { name: "Car Parking Systems" },
          { name: "Server Room & Data Centre Work" },
          { name: "Intercom Systems" },
          { name: "Gate Barrier Systems" },
          { name: "PA Systems(Public Address Systems)" },
          { name: "Room & Home Automation Systems" },
          { name: "Time & Attendance Systems" },
          { name: "SMATV/IPTV Systems" },
        ],
      },

      {
        id: "annual-maintenance-contracts-services-in-UAE",
        canonical: 'https://daralataa.com/service/annual-maintenance-contracts-services-in-UAE',
        headImage: head9,
        img: img9,
        title: "Annual Maintenance Contracts (AMC) Services in UAE",
        pagedescription:
          "Experience peace of mind with Dar Al Ataa's Annual Maintenance Contract in Dubai, ensuring your property stays in perfect condition all year round.",
        name: "Trusted Annual Maintenance Contracts (AMC) Services in UAE",
        description:
          "When it comes to maintaining the smooth and efficient operation of your property, regular maintenance is key. We know that regular maintenance is essential in preventing unexpected breakdowns and costly repairs. That’s why we offer a range of Annual Maintenance Contracts (AMC) in Dubai, to meet the unique needs of each client, so that all systems are running optimally throughout the year.",
          description8_1:
          "An Annual Maintenance Contract in UAE is not just a service agreement; it's an approach to facility management. With our AMC, you get peace of mind knowing that all essential services, including electrical, plumbing, HVAC, and more, are regularly inspected and maintained. This proactive care reduces the risk of unforeseen issues, extends the lifespan of your systems, and ultimately saves you money in the long run.",
          description8_2:
          "Every property differs, so our Annual Maintenance Contract in Dubai is fully customizable. Regular checks for your electrical systems, plumbing inspections, routine HVAC servicing, etc., all get covered by our AMC Services. Our AMC packages are designed to cater to your specific requirements. Our team works closely with you to understand your needs and develop a maintenance plan that keeps your property in top condition all year round.",
          headding7:
          "Reliable Services with a Proven Track Record",
        
        description2_1:
          "At Dar Al Ataa, we deliver reliable and efficient AMC services in UAE. With our experienced team, you can rest assured that your property is in safe hands. Our Annual Maintenance Contract in Dubai covers everything from minor repairs to major system overhauls, which makes your building remain fully functional, secure, and compliant with all regulations.",
        
        headding3: "The Benefits of Our AMC Services",
        description3: 
          "Choosing our Annual Maintenance Contract in UAE means more than just regular maintenance. It means having a dedicated team available 24/7 to address any issues that may arise. It means avoiding the stress and expense of unexpected repairs. Most importantly, it means preserving the value and functionality of your property, making everything run smoothly, day in and day out.",

        headding4: "Proactive Care for Long-Term Savings",
        description4: 
        "One of the biggest advantages of our Annual Maintenance Contract in Dubai is the long-term savings it offers. By catching small issues before they become big problems, our AMC services prevent costly emergency repairs and extend the lifespan of your systems. This approach is not just cost-effective; it’s also environmentally responsible, as well-maintained systems consume less energy and resources.",

        headding5: "A Partnership You Can Trust",
        description5:
          "With our Annual Maintenance Contract in UAE, you’re entering a partnership with a company that cares about your property as much as you do. We deliver high-quality, reliable AMC services that keep your property running smoothly, efficiently, and safely all year long.",
          
          description6:
          "An Annual Maintenance Contract in Dubai from Dar Al Ataa is your assurance of peace of mind, cost savings, and the reliable upkeep of your property. our AMC services ensure that your investment remains in excellent condition, providing a safe and comfortable environment for all occupants.",
          
        points: [
          
          { name: "Service & Maintenance of A/C" },
          { name: "Service & Maintenance of Electrical System" },
          { name: " Service & Maintenance of Plumbing System" },
          { name: "Pest control" },
          { name: "Water tank cleaning" },

        ],
      },

      {
        id: "interior-fit-out-company-in-dubai-uae",
        canonical: 'https://daralataa.com/service/interior-fit-out-company-in-dubai-uae',
        headImage: head5,
        img: img5,
        name: "The Best Interior Fit-Out Company in Dubai",

        title: "Best interior fit-out company in Dubai",
        pagedescription:
          "Dar Al Ataa is the best interior fit-out company in Dubai, offering comprehensive design and construction solutions for residential, retail, and corporate clients.",
        
          description8_8:
          "In crafting outstanding interior spaces that harmoniously integrate functionality, aesthetics, and innovation, Dar Al Ataa emerges as the premier interior design and construction company in Dubai. With extensive experience and a team of highly proficient professionals, we provide top interior fit-out solutions designed to cater to the varied requirements of residential, retail, and corporate clients. Whether it involves a lavish residence, a dynamic retail environment, or a modern corporate office, our objective is to transform your vision into reality with unmatched precision and creativity.",
           headding2:
          "Best Interior Solutions for Every Space",
        
        description2_1:
          "At Dar Al Ataa, we recognize that each space possesses uniqueness and necessitates a tailored approach. Our skilled team of interior construction and design experts offers a wide range of services, encompassing architectural design, project management, and the execution of interior works. We are dedicated to delivering projects that not only fulfill but surpass client expectations, all while upholding the highest standards of quality and craftsmanship.",
        
      
        headding3: "Our Top interior fit-out services encompass the following:",

        

        description3: (
          <>
            <strong>Architectural Design, 3D Visualization, and Rendering: </strong> 
             Our adept interior designers bring your concepts to fruition through state-of-the-art 3D visualization and rendering techniques. This approach guarantees that you receive a clear and realistic preview of your project before its commencement. As a leading interior fit-out company in Dubai, we attend to every detail, from layout and color schemes to furniture arrangement and lighting, ensuring a cohesive and visually appealing design.
          </>
        ),
        description3_1: (
          <>
            <strong>Project Management, Coordination, and Consulting: </strong> 
            Efficient project management is fundamental for the top interior fit-out process. We adopt a holistic approach, overseeing every facet of your project from inception to completion. Our team coordinates all trades and services, guarantees timely delivery, and manages costs, allowing you to enjoy a seamless experience and a beautifully completed space.
          </>
        ),
        description3_2: (
          <>
            <strong>Comprehensive Management of Interior Works: </strong> 
            We oversee every facet of the interior construction and design, from the initial design phase to the final execution. As seasoned interior fit-out contractors, we excel in various types of interior projects, ensuring that each component harmonizes seamlessly with your design aspirations.
          </>
        ),
        
        headding4: "Specialized Carpentry and Custom Joinery",
        description4: 
        "The talented carpenters and artisans at Dar Al Ataa focus on crafting bespoke furniture, cabinetry, and woodwork that enrich the aesthetic and functionality of your environment. Our carpentry offerings address a wide range of needs, from elegant wooden paneling and chic wardrobes to tailor-made furniture items. We take pride in merging traditional craftsmanship with contemporary technology to produce pieces that are both visually appealing and long-lasting.",
     
        headding5: "Creative Lighting Design and Implementation",
        description5:
          "Lighting is essential in establishing the ambiance and enhancing the visual appeal of a space. As the best interior fit-out company in Dubai, we offer specialized lighting design and installation services that enliven your interiors. Our specialists will assist you in selecting the ideal lighting options, including ambient, task, accent, and statement fixtures. We ensure that each light source is strategically positioned to accentuate architectural features, improve functionality, and foster a warm and welcoming environment.",
          
          headding6: "Wall Treatments and Artistic Finishes",
        description6:
          "We provide a wide array of wall treatment options, including sophisticated wallpapers, modern paint finishes, and distinctive materials such as gypsum, glass, and wood. Our team collaborates closely with you to identify the most suitable finishes that align with your overall design vision. Whether you aim to create a striking focal point with an accent wall or establish a tranquil backdrop with subtle hues, we possess the expertise to achieve exceptional outcomes.",
          
          headding7: "Creating Spaces That Inspire",
          description7:
            "At Dar Al Ataa, we hold the conviction that a thoughtfully crafted interior transcends mere visual appeal; it is about cultivating environments that inspire and enrich the lives of their occupants. Whether you aim to renovate your residence, refresh your workplace, or establish a new retail space, our team is dedicated to assisting you in creating an atmosphere that embodies your distinct style and fulfills your specific requirements.",
            description7_1:
            "Our top interior fit-out services are meticulously designed to achieve a harmonious integration of functionality, comfort, and aesthetic beauty. From the initial concept to the final execution, we collaborate closely with you to ensure that every detail is meticulously addressed and that each component serves a meaningful purpose.",
            headding8: "Discover the Dar Al Ataa Difference",
            description11:
              "By choosing Dar Al Ataa, you are aligning yourself with a team that is devoted to excellence in all facets of interior design and construction. We are committed to delivering superior services that distinguish themselves in the competitive landscape of Dubai. As a reputable interior design and construction company in Dubai, we boast a proven history of executing exceptional projects that not only meet but surpass our clients' expectations.",
              description8_1:
              "Our objective is to assist you in crafting a space that you cherish and enjoy, one that enhances your lifestyle and actualizes your vision. With our expertise, creativity, and commitment, we are poised to transform your dream space into reality.",
              headding9: "Reach Out to Us Today!",
              description8_9:
              "Are you prepared to revitalize your space? Connect with Dar Al Ataa, the top interior fit-out company in Dubai, and explore how our innovative solutions can elevate your interiors to unprecedented levels. Contact us today to arrange a consultation and embark on your journey toward creating a truly remarkable space.",
              

        points: [
          { name: "Architectural Design, 3D Visualization and Rendering." },
          { name: "Project Management, Coordination and Consulting." },
          { name: "All interior work Execution." },
          { name: "Carpentry works." },
          { name: "Lighting Design and Installation." },
          { name: "Wall Covering and Artwork (eg: Gypsum, glass, painting and wallpaper fixing)" },
        ],
      },
      {
        id: "building-materials-trading-service-company-in-dubai",
        canonical: 'https://daralataa.com/service/building-materials-trading-service-company-in-dubai',
        headImage: head6,
        img: img6,
        title: "Top-quality Material Building Supply in UAE",
        pagedescription:
          "Dar Al Ataa offers top-quality Material Building Supply in UAE, providing a wide range of essential construction materials with unmatched reliability and service.",
        
        name: "Top-Quality Material Building Supply in Dubai",
        
        description8_1:
        "In the world of construction, the quality of materials plays a critical role in ensuring durability, safety, and overall success. Dar Al Ataa, a leading building materials trading service company in Dubai, is well-positioned to meet the growing demand for high-quality materials. With a broad spectrum of offerings such as Workshop Hardware & Tools, Pipes & Fittings, Paints & Varnish, and much more, Dar Al Ataa has established itself as a reliable partner in the UAE’s construction sector.",
        description8_2:
        "The UAE has been at the forefront of global construction projects for decades, and as a result, there is a substantial need for trustworthy suppliers like Dar Al Ataa. Whether it’s for small-scale residential projects or grand infrastructure developments, material building supply in UAE has become an essential component of the industry. Dar Al Ataa’s diverse inventory ensures that contractors and builders can find everything they need, from nuts and bolts to more specialized items like false ceilings.",
        headding7:
        "The Role of Material Building Supply in Dubai’s Construction Boom",
        description2_1:
        "Dubai’s skyline is known for its impressive buildings and innovative architecture, which would not be possible without reliable building material suppliers in UAE. Dar Al Ataa is proud to be part of this transformative landscape, supplying materials that ensure the safety, efficiency, and longevity of construction projects. Our expertise in material building supply in Dubai goes beyond just providing materials; we offer solutions that cater to the diverse needs of the construction industry.",

        description2_5:
        "At Dar Al Ataa, we believe that quality materials are the backbone of any successful project. Our range of building materials is sourced from top manufacturers, ensuring that they meet international standards. This commitment has made us one of the most trusted building material trading in Dubai companies, with a reputation for delivering top-notch products on time.",

        headding3:
        "Workshop Hardware & Tools Trading",
        description13:
        "One of the core offerings of Dar Al Ataa is our Workshop Hardware & Tools Trading service. We provide a wide range of tools and hardware that are essential for construction and maintenance work. From basic hand tools to specialized machinery, our inventory is designed to meet the needs of professionals in the industry. In a fast-paced construction environment, having access to the right tools can make all the difference. At Dar Al Ataa, we ensure that our clients have the tools they need to get the job done efficiently. Whether it’s for large-scale construction or small maintenance jobs, we’ve got it covered with our comprehensive range of hardware.",

        headding4:
        "Building & Construction Material Trading",

        description4:
        "As a leading material building supplier in UAE, Dar Al Ataa’s Building & Construction Material Trading service is one of the most critical aspects of our business. We provide a variety of construction materials, including cement, aggregates, and reinforcement bars, which are essential for building strong, durable structures.",

        description3:
        "We work closely with contractors, engineers, and architects to ensure that they receive the materials that match the specific needs of their projects. Our long-standing relationships with top manufacturers enable us to provide high-quality materials at competitive prices, further solidifying our position as a top building materials trading service company in Dubai.",

        headding5:
        "Bolts, Nuts, Screws & Nails Trading",
        description5:
        "No construction project can be completed without the right fasteners. Dar Al Ataa’s Bolts, Nuts, Screws & Nails Trading service ensures that our clients have access to a comprehensive range of fasteners in different sizes and specifications. These small yet vital components are crucial for the stability and safety of any construction.",

        description5_1:
        "Our extensive inventory of fasteners is sourced from reliable manufacturers, ensuring that they meet the rigorous standards required for construction in the UAE. From stainless steel screws to heavy-duty bolts, we provide everything needed to ensure that projects are securely assembled.",

        headding1:
        "Pipes & Fittings Trading",
        description11:
        "Pipes and fittings are essential for any construction project, particularly in plumbing, HVAC, and electrical systems. Dar Al Ataa’s Pipes & Fittings Trading service provides a wide variety of options for builders and contractors. Whether it’s PVC pipes for residential plumbing or metal fittings for commercial HVAC systems, we offer products that cater to a range of needs.",
        description2_3:
        "We understand the importance of quality when it comes to pipes and fittings, which is why all of our products are sourced from trusted manufacturers. This guarantees that our clients receive durable, long-lasting materials that can withstand the harsh environmental conditions often faced in the UAE.",

        headding2:
        "Paints & Varnish Trading",
        description12:
        "The final touch on any construction project is the finish, and Dar Al Ataa’s Paints & Varnish Trading service provides a wide range of products to give buildings that polished look. We offer high-quality paints and varnishes that not only enhance the aesthetic appeal of a structure but also provide protection against the elements.",
        description3_3:
        "Our selection includes products for both interior and exterior applications, ensuring that builders can find the right finish for any project. As with all of our offerings, our paints and varnishes are sourced from top manufacturers, ensuring quality and durability.",
        headding6:
        "False Ceiling Trading",
        description6:
        "Dar Al Ataa also specializes in False Ceiling Trading, providing materials that help improve the aesthetic and acoustic qualities of a building. False ceilings are commonly used in commercial and residential spaces to hide wiring, pipes, and other infrastructure, while also providing soundproofing and insulation.",
        description3_4:
        "We offer a variety of false ceiling options, including gypsum, metal, and mineral fiber, allowing our clients to choose the right product for their specific needs. Our false ceilings are not only functional but also stylish, helping to enhance the overall look of any space.",
        description3_5:
        "As a top Building Material supplier in UAE, Dar Al Ataa provides the best products and services to our clients. Our focus on quality, reliability, and customer satisfaction sets us apart from other suppliers in the industry. With a wide range of products and a dedication to excellence, Dar Al Ataa is the go-to material building supplier in Dubai. ",
        description3_6:
        "Dar Al Ataa stands as a reliable and leading building material supplier in UAE, offering a wide range of high-quality materials that cater to the diverse needs of the construction industry. Whether you need tools, fasteners, pipes, or false ceilings, we’ve got you covered. Our commitment to quality, competitive pricing, and timely delivery makes us the preferred Building Material Trading in Dubai partner for contractors and builders. With Dar Al Ataa, you can trust that your project will be built on a foundation of quality materials and expert service.",



        points: [
          { name: "Workshop Hardware & Tools Trading" },
          { name: "Building & Construction Material Trading" },
          { name: "Bolts,Nuts,Screws & Nails Trading" },
          { name: "Pipes & Fitting Trading" },
          { name: "Paints & Varnish Trading" },
          { name: "False Ceiling Trading" },
        ],
      },
    ];
    await this.setState({ services });
  }

  render() {
    const {
      data,
      points,
      description3,
      description4,
      description5,
      description6,
      description7,
      description11,
      description12,
      description13,
    } = this.state;

    return (
      <div className="service-profile-page">
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.pagedescription} />
          <link rel="canonical" href={data.canonical} />
        </Helmet>
        <section
          id="service-header"
          style={{ backgroundImage: `url(${data.headImage})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center">
                <div className="service-heading">
                  <h1>{data.name}</h1>
                </div>
              </div>
            </div>
            <div className="view-more">
              <div className="bounce">
                <HashLink to="#service-details">
                  <i className="icon-down-open" />
                </HashLink>
              </div>
            </div>
          </div>
        </section>
        <section id="service-details">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="service-content">
                  <div className="heading theme-line">
                    <h3>{data.name}</h3>
                  </div>
                  <div className="text-justify">
                    <p>{data.description}</p>
                  </div>
                  <ul>
                    {points.map((point, index) => (
                      <li key={index}>
                        {point.link ? (
                          <Link to={point.link}>{point.name}</Link>
                        ) : (
                          point.name
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={data.img} alt={data.name} />
              </div>
            </div>
            <div className="text-justify">
                    <p>{data.description12}</p>
            </div>
            <h2 className="fw-600 mt-3 mb-2">{data.heading13}</h2>
            <div className="text-justify">
                    <p>{data.description13}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading14}</h3>
            <div className="text-justify">
                    <p>{data.description14}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading15}</h3>
            <div className="text-justify">
                    <p>{data.description15}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading16}</h3>
            <div className="text-justify">
                    <p>{data.description16}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading17}</h3>
            <div className="text-justify">
                    <p>{data.description17}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading18}</h3>
            <div className="text-justify">
                    <p>{data.description18}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading19}</h3>
            <div className="text-justify">
                    <p>{data.description19}</p>
            </div>
            <h3 className="fw-600 mt-3 mb-2">{data.heading20}</h3>
            <div className="text-justify">
                    <p>{data.description20}</p>
            </div>
            
            {/* Render additional sections based on service ID */}
            {data.id === "top-civil-construction-company-in-dubai-uae" && (
              <div className="row">
              <div className="col-md-12">
              <p className="mb-08">
                  <strong>{data.strongdescription2_1}</strong>
                  {data.description8_1}
                </p>
                
                <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                <p className="mb-08">
                  <strong>{data.strongdescription2_1}</strong>
                  {data.description2_1}
                </p>
                
                <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                <p>{description3}</p>
                
                <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                <p>{description4}</p>
               
                <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                <p>{description5}</p>
                <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                <p>{description6}</p>
                         
              </div>
            </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "electrical-services-in-dubai-uae" && (
              <div className="row">
                <div className="col-md-12">

                <p className="mb-08">
                    <strong>{data.strongdescription2}</strong>
                    {data.description2}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding2}</h3>
                  
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description2_2}
                  </p>
                  
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description3}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription3_1}</strong>
                    {data.description3_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription4_1}</strong>
                    {data.description4_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription5_1}</strong>
                    {data.description5_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription5_2}</strong>
                    {data.description5_2}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                  <p>{description6}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription6_1}</strong>
                    {data.description6_1}
                  </p>

                  
                </div>
              </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "annual-maintenance-contracts-services-in-UAE" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description8_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description8_2}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
                          
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description3}</p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <p>{description6}</p>
                            
                </div>
              </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "ict-service-company-in-dubai" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description8_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description2_2}
                  </p>
                  
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description3}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                  <p>{description6}</p>
                             
                </div>
              </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "ict-service-company-in-dubai" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description8_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description2_2}
                  </p>
                  
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description3}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                  <p>{description6}</p>
                             
                </div>
              </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "best-plumbing-service-in-dubai-uae" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description8_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description8_2}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description2_2}
                  </p>
                  
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description3}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description4_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description5_1}
                  </p>
                  
                             
                </div>
              </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "interior-fit-out-company-in-dubai-uae" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description8_8}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding2}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description2_1}
                  </p>
  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  {/* Add the static unordered list here */}
                  <ul>
                      <li>Architectural Design, 3D Visualization and Rendering.</li>
                      <li>Project Management, Coordination, and Consulting.</li>
                      <li>All interior work Execution.</li>
                      <li>Carpentry works.</li>
                      <li>Lighting Design and Installation.</li>
                      <li>Wall Covering and Artwork (e.g., Gypsum, glass, painting, and wallpaper fixing).</li>
                    </ul>
                  <p>{description3}</p>

                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_2}
                  </p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description5_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                  <p>{description6}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p>{description7}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description7_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding8}</h3>
                  <p>{description11}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description8_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding9}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description8_9}
                  </p>
                             
                </div>
              </div>
            )}
            {/* Render additional sections based on service ID */}
            {data.id === "hvac-system-in-dubai-uae" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription8_1}</strong>
                    {data.description8_1}
                  </p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description13}</p>
                  <p>{description3}</p>

                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_2}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_3}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_4}
                  </p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description5_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                  <p>{description6}</p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p>{description7}</p>
                  
                             
                </div>
              </div>
            )}

            {/* Render additional sections based on service ID */}
            {data.id === "building-materials-trading-service-company-in-dubai" && (
              <div className="row">
                <div className="col-md-12">
                <p className="mb-08">
                    <strong>{data.strongdescription8_1}</strong>
                    {data.description8_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription8_1}</strong>
                    {data.description8_2}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding7}</h3>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_1}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_1}</strong>
                    {data.description2_5}
                  </p>
  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding3}</h3>
                  <p>{description13}</p>

                 
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding4}</h3>
                  <p>{description4}</p>
                  <p>{description3}</p>
                  
                  <h3 className="fw-600 mt-3 mb-2">{data.headding5}</h3>
                  <p>{description5}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description5_1}
                  </p>
                  <h3 className="fw-600 mt-3 mb-2">{data.headding1}</h3>
                  <p>{description11}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description2_3}
                  </p>

                  <h3 className="fw-600 mt-3 mb-2">{data.headding2}</h3>
                  <p>{description12}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_3}
                  </p>


                  <h3 className="fw-600 mt-3 mb-2">{data.headding6}</h3>
                  <p>{description6}</p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_4}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_5}
                  </p>
                  <p className="mb-08">
                    <strong>{data.strongdescription2_2}</strong>
                    {data.description3_6}
                  </p>
                  
                  
                             
                </div>
              </div>
            )}
          </div>
        </section>
        <section id="contactus-widget">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center">
                IF YOU CAN ENVISION IT, THEN WE CAN BUILD IT TELL US MORE ABOUT
                YOUR PROJECT
                <Link to="/contact">
                  <span className="btn">CONTACT US</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ServiceProfilePage;
